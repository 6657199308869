@charset "utf-8";

//変数の定義
$base-width: 840px;
$display-width-s: 768px;
$base-color: #fff;
$font-color-base: #333;
$font-color-light: #333;
$color-theme: #000;

@mixin mq-sp {
    @media screen and (min-width: 0) and (max-width: $display-width-s) {
        @content;
    }
}
@mixin mq-desktop {
    @media screen and (min-width: $display-width-s) {
        @content;
    }
}

//フォントの定義
%font {
  font-size: 1.6rem;
  font-weight: 400;
  font-family: "ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","游ゴシック Medium",YuGothic,YuGothicM,"メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
  color: $font-color-light;
  line-height: 2.125;
  @include mq-sp{ font-size: 1.5rem; line-height: 1.71428; } //::スマホ
}

%font-min {
  font-weight: 500;
  font-family: "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

%font-nikkatsu {
  font-family: ro-nikkatsu-sei-kai-std, serif;
  font-weight: 400;
  font-style: normal;
  writing-mode: vertical-rl;
}

%font-montserrat {
  font-family: 'Montserrat';
}

//タイトルの定義
@mixin ttl-base($size, $lineHeight:1.26315, $weight:700, $color:$font-color-base) {
  font-size: $size;
  line-height: $lineHeight;
  font-weight: $weight;
  color: $color;
  font-family: 'Montserrat',"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","游ゴシック Medium",YuGothic,YuGothicM,"メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
}

//余白の定義
%margin-wrap {
  @include mq-sp{//::スマホ
    margin: 0 8px;
  }
}
%margin-wrap-in {
  margin: 0 24px;
  @include mq-sp{//::スマホ
    margin: 0 12px;
  }
}